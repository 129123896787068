import React from 'react';
import { useState, createContext, useEffect } from "react";
import netlifyIdentity from "netlify-identity-widget";
import { navigate } from 'gatsby';


const details = {
    user: null,
    isAuthorized: false
}

const AuthContext = createContext(details)

export const AuthContextProvider = ({ children }) => {

    useEffect(() => {
        netlifyIdentity.init({})

        if(localStorage.getItem('rivalschool')){
            const token = JSON.parse(localStorage.getItem('rivalschool'))
            details.user = token.user;
            details.isAuthorized = token.isAuthorized;
        }

    }, [])

    netlifyIdentity.on('login', user => {
        netlifyIdentity.close();
        details.user = user;
        details.isAuthorized = true;
        localStorage.setItem('rivalschool', JSON.stringify(details));
        navigate('/team');
    })

    netlifyIdentity.on('logout', user => {
        details.user = null;
        details.isAuthorized = false;
        localStorage.removeItem('rivalschool');
    })

    return(
        <AuthContext.Provider>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;