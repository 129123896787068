import React from "react"
import PropTypes from "prop-types"
import "normalize.css"
import "./layout.css"
import { AuthContextProvider } from "../store/authContext";

const Layout = ({ children }) => {

  return (
    <AuthContextProvider>
      <main>{children}</main>
    </AuthContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
